/*
Template Name: Color Admin - Responsive Admin Dashboard Template build with Twitter Bootstrap 3.3.7 & Bootstrap 4.0.0-Alpha 6
Version: 3.0.0
Author: Sean Ngu
Website: http://www.seantheme.com/color-admin-v3.0/admin/material/
*/

.navbar-logo,
.coming-soon .brand .logo,
.login .login-header .brand .logo {
    border-color: #4DD0E1 #00BCD4 #0097A7;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:focus, 
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top {
    background: #00BCD4;
}
.navbar.navbar-inverse .navbar-brand {
    color: #fff !important;
}
.text-theme,
.sidebar .sub-menu > li.active > a:before {
    color: #00BCD4;
}


/* Added in v1.5 */

.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a, 
.gradient-enabled .sidebar .nav > li.active > a:focus, 
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a, 
.gradient-enabled .top-menu .nav > li.active > a:focus, 
.gradient-enabled .top-menu .nav > li.active > a:hover {
    background: #4dd0e1; /* Old browsers */
    background: -moz-linear-gradient(top,  #4dd0e1 0%, #00acc1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #4dd0e1 0%,#00acc1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #4dd0e1 0%,#00acc1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dd0e1', endColorstr='#00acc1',GradientType=0 ); /* IE6-9 */
}
.pace-progress {
    background: #00BCD4;
}
.pace .pace-activity {
    border-top-color: #00BCD4;
    border-left-color: #00BCD4;
}


/* Added in v1.7 */

.page-with-light-sidebar .sidebar .nav > li.active > a > i {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a .badge,
.page-with-light-sidebar .sidebar .nav > li > a .label.label-theme,
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
    background: #E91E63;
}
.page-with-light-sidebar .sidebar .text-theme {
    color: #E91E63;
}


/* Added in v1.9 */

.top-menu .nav > li.active > a, 
.top-menu .nav > li.active > a:focus, 
.top-menu .nav > li.active > a:hover {
    background: #00BCD4;
}